import { createAction, props } from '@ngrx/store';
import { ApiError } from 'src/domain/api-error';

const feature = '[Auth]';

export const loginWithMagicToken = createAction(`${feature} login with magic token`, props<{ token: string; shouldNavigate: boolean }>());

export const login = createAction(`${feature} login`, props<{ email: string; password: string; shouldNavigate: boolean }>());
export const loginSuccess = createAction(`${feature} login success`, props<{ shouldNavigate: boolean }>());
export const loginError = createAction(`${feature} login error`, props<{ error: ApiError }>());

export const logout = createAction(`${feature} logout`, props<{ showDialog: boolean }>());
export const logoutSuccess = createAction(`${feature} logout success`);
export const logoutError = createAction(`${feature} logout error`, props<{ error: ApiError }>());

export const requestSecondFactorCode = createAction(`${feature} request second factor code`, props<{ isResent: boolean }>());
export const requestSecondFactorCodeSuccess = createAction(`${feature} request second factor code success`, props<{ isResent: boolean }>());
export const requestSecondFactorCodeError = createAction(`${feature} request second factor code error`, props<{ error: ApiError }>());

export const secondFactorAuthenticate = createAction(
  `${feature} second factor authenticate`,
  props<{ code: string; shouldNavigate: boolean }>()
);
export const secondFactorAuthenticateSuccess = createAction(
  `${feature} second factor authenticate success`,
  props<{ shouldNavigate: boolean }>()
);
export const secondFactorAuthenticateError = createAction(`${feature} second factor authenticate error`, props<{ error: ApiError }>());

export const storeInitialUrlBeforeLogin = createAction(`${feature} store initial url before login`, props<{ initialUrl?: string }>());
export const clearInitialUrl = createAction(`${feature} clear initial url after login success`);

export const resetState = createAction(`${feature} reset state`);
