import { Location } from '@angular/common';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { AuthDataService, AuthTwoFactorDataService, ProfileDataService } from '@dd/shop-client-sdk';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { TagEvent } from '@shared/enums/tags/tag-event.enum';
import { getProfile } from '@shared/store/profile/profile.actions';
import { catchError, delay, map, of, switchMap } from 'rxjs';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { LoginFormComponent } from '../components/login-form/login-form.component';
import {
  login,
  loginError,
  loginSuccess,
  loginWithMagicToken,
  logout,
  logoutError,
  logoutSuccess,
  requestSecondFactorCode,
  requestSecondFactorCodeError,
  requestSecondFactorCodeSuccess,
  secondFactorAuthenticate,
  secondFactorAuthenticateError,
  secondFactorAuthenticateSuccess
} from './auth.actions';
import { selectInitialUrlBeforeLogin } from './auth.selectors';

const LOGIN_THROTTLE_TIME = 2000;

@Injectable()
export class AuthEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public login$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(login),
      switchMap(({ email, password, shouldNavigate }) => {
        return this.authDataService.login(email, password).pipe(
          delay(LOGIN_THROTTLE_TIME),
          map(() => {
            return loginSuccess({ shouldNavigate });
          }),
          catchError((error: HttpErrorResponse) => {
            this.pushLoginErrorTag(error);
            return of(loginError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public loginWithMagicToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginWithMagicToken),
      switchMap(({ token, shouldNavigate }) => {
        return this.profileDataService.loginViaToken({ token }).pipe(
          map(() => {
            return loginSuccess({ shouldNavigate });
          }),
          catchError((error: HttpErrorResponse) => {
            this.pushLoginErrorTag(error);
            return of(loginError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public loginSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loginSuccess),
      concatLatestFrom(() => this.store.select(selectInitialUrlBeforeLogin)),
      map(([{ shouldNavigate }, initialUrl]) => {
        if (initialUrl && shouldNavigate) {
          this.router.navigateByUrl(initialUrl);
        } else if (shouldNavigate) {
          this.tagPusherService.pushTag({
            event: TagEvent.Login,
            type: 'results'
          });
          this.router.navigateByUrl(RouteSegment.Dashboard);
        } else {
          this.tagPusherService.pushTag({
            event: TagEvent.Login,
            type: 'payment'
          });
        }
        return getProfile();
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public requestSecondFactorCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestSecondFactorCode),
      switchMap(({ isResent }) => {
        return this.twoFactorDataService.sendCode().pipe(
          map(() => {
            if (isResent) {
              this.tagPusherService.pushTag({
                event: TagEvent.LoginCodeResent
              });
            }

            return requestSecondFactorCodeSuccess({ isResent });
          }),
          catchError((error: HttpErrorResponse) => {
            this.pushCodeRequestErrorTag(error);
            return of(requestSecondFactorCodeError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public secondFactorAuthenticationSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(secondFactorAuthenticateSuccess),
      switchMap(({ shouldNavigate }) => {
        return of(loginSuccess({ shouldNavigate }));
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public secondFactorAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(secondFactorAuthenticate),
      switchMap(({ code, shouldNavigate }) => {
        return this.twoFactorDataService.verifyCode({ code }).pipe(
          map(() => {
            return secondFactorAuthenticateSuccess({ shouldNavigate });
          }),
          catchError((error: HttpErrorResponse) => {
            this.pushSecondFactorErrorTag(error);
            return of(secondFactorAuthenticateError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(logout),
      switchMap(({ showDialog }) =>
        this.authDataService.logout().pipe(
          map(() => {
            const path = this.location.path(true);
            // We only navigate the user back if they were originally on the dashboard to avoid unnecessary navigation

            if (showDialog) {
              this.dialog.open<LoginFormComponent>(LoginFormComponent, {
                backdropClass: 'dd-blur-backdrop',
                dialogModeWidth: '480px'
              });
            }

            if (path.includes(RouteSegment.Dashboard)) {
              this.router.navigate([RouteSegment.Root]);
            }

            return logoutSuccess();
          }),
          catchError((error: HttpErrorResponse) => of(logoutError({ error: mapApiErrorResponse(error) })))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly authDataService: AuthDataService,
    private readonly profileDataService: ProfileDataService,
    private readonly twoFactorDataService: AuthTwoFactorDataService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly location: Location,
    private readonly tagPusherService: TagPusherService,
    private readonly dialog: BottomSheetAndDialog
  ) {}

  public pushCodeRequestErrorTag(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpStatusCode.Forbidden: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'too_many_code_requests'
        });
        break;
      }
      case HttpStatusCode.Unauthorized: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'expired'
        });
        break;
      }
      default: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'unknown'
        });
      }
    }
  }

  public pushLoginErrorTag(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpStatusCode.Forbidden: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'too_many_attempts'
        });
        break;
      }
      case HttpStatusCode.Unauthorized: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'wrong_credentials'
        });
        break;
      }
      default: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'unknown'
        });
      }
    }
  }

  public pushSecondFactorErrorTag(error: HttpErrorResponse): void {
    switch (error.status) {
      case HttpStatusCode.Forbidden: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'too_many_attempts'
        });
        break;
      }
      case HttpStatusCode.Unauthorized: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'expired'
        });
        break;
      }
      case HttpStatusCode.Conflict: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'wrong_code'
        });
        break;
      }
      default: {
        this.tagPusherService.pushTag({
          event: TagEvent.LoginError,
          error_code: error.status,
          error_message: error.message,
          error_type: 'unknown'
        });
      }
    }
  }
}
