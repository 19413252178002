import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { storeInitialUrlBeforeLogin } from '@core/auth/store/auth.actions';
import { Store } from '@ngrx/store';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { debounceTime, Subject } from 'rxjs';

const MULTIPASS_LOGIN_TIMEOUT = 840_000;

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  private readonly location = inject(Location);
  private readonly authService = inject(AuthService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);

  private readonly logoutTimerObservable$: Subject<void> = new Subject();
  private readonly logoutTimer$ = this.logoutTimerObservable$?.pipe(debounceTime(MULTIPASS_LOGIN_TIMEOUT));

  constructor() {
    this.logoutTimer$.subscribe(() => {
      return this.logout();
    });
  }

  public startAutoLogoutTimer(): void {
    this.logoutTimerObservable$.next();
  }

  private logout() {
    const path = this.location.path(true);
    if (path.includes(RouteSegment.Dashboard)) {
      this.store.dispatch(storeInitialUrlBeforeLogin({ initialUrl: `${path}` }));
      this.authService.logout(true);
    }
  }
}
