import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutoLogoutService } from '@core/services/auto-logout.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly autoLogoutService = inject(AutoLogoutService);

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes('results') || req.url.includes('shop/orders') || req.url.includes('shop/invoices')) {
      this.autoLogoutService.startAutoLogoutTimer();
    }
    return next.handle(req);
  }
}
