import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginFormComponent } from '@core/auth/components/login-form/login-form.component';
import { ProfileDataService } from '@dd/shop-client-sdk';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BottomSheetAndDialog } from '@shared/components/bottom-sheet/bottom-sheet-and-dialog';
import { RouteSegment } from '@shared/enums/route-segment.enum';
import { ToastMessageType } from '@shared/modules/toast-message/toast-message-type.enum';
import { ToastMessageService } from '@shared/modules/toast-message/toast-message.service';
import { asyncScheduler, catchError, map, of, switchMap } from 'rxjs';
import { noopAction } from 'src/app/stores/noop.action';
import { mapApiErrorResponse } from 'src/domain/mappers/error';
import { mapApiProfileResponse } from 'src/domain/mappers/map-profile';
import { TagPusherService } from '@core/services/tag-pusher.service';
import { throttleTimeAfterFirst } from '@shared/helpers/debounce-after-first.helper';
import { AutoLogoutService } from '@core/services/auto-logout.service';
import { TagEvent } from '../../enums/tags/tag-event.enum';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  getProfile,
  getProfileError,
  getProfileIfNeeded,
  getProfileSuccess,
  resetState,
  updateAddress,
  updateAddressError,
  updateAddressSuccess,
  updatePersonalDetails,
  updatePersonalDetailsError,
  updatePersonalDetailsSuccess
} from './profile.actions';
import { selectProfile, selectProfileVM } from './profile.selectors';

const GET_PROFILE_THROTTLE_TIME = 2000;

@Injectable()
export class ProfileEffects {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProfileIfNeeded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProfileIfNeeded),
      concatLatestFrom(() => this.store.select(selectProfile)),
      switchMap(([_, profile]) => {
        if (profile.data || !!profile.error) {
          return of(noopAction());
        }

        return of(getProfile());
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProfile),
      throttleTimeAfterFirst(GET_PROFILE_THROTTLE_TIME, asyncScheduler, { trailing: true, leading: true }),
      switchMap(() => {
        return this.profileDataService.getProfile().pipe(
          map((profile) => {
            return getProfileSuccess({ profile: mapApiProfileResponse(profile) });
          }),
          catchError((error: HttpErrorResponse) => of(getProfileError({ error: mapApiErrorResponse(error) })))
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProfileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProfileSuccess),
      switchMap(({ profile }) => {
        this.tagPusherService.pushTag({
          event: TagEvent.UserIdSet,
          user_id: profile.shopifyId
        });
        return of(noopAction());
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public updatePersonalDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePersonalDetails),
      switchMap(({ personalDetails }) => {
        return this.profileDataService.updateProfilePersonalDetails(personalDetails).pipe(
          map((profile) => {
            return updatePersonalDetailsSuccess({ profile: mapApiProfileResponse(profile) });
          }),
          catchError((error: HttpErrorResponse) => {
            this.showErrorMessage();
            if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
              this.navigateToShopAndOpenLoginDialog();
            }
            return of(updatePersonalDetailsError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public updatePersonalDetailsSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updatePersonalDetailsSuccess),
      switchMap(({ profile }) => {
        this.showSuccessMessage();
        this.navigateToProfile();
        return of(getProfileSuccess({ profile }));
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public updateAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAddress),
      switchMap(({ address, redirect }) => {
        return this.profileDataService.updateProfileShippingAddress(address).pipe(
          map((profile) => {
            if (redirect) {
              this.showSuccessMessage();
              this.navigateToProfile();
            }

            return updateAddressSuccess({ profile: mapApiProfileResponse(profile) });
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
              this.navigateToShopAndOpenLoginDialog();
            } else if (redirect) {
              this.showErrorMessage();
              this.navigateToProfile();
            }

            return of(updateAddressError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public changePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePassword),
      switchMap(({ changePasswordRequest }) => {
        return this.profileDataService.changeProfilePassword(changePasswordRequest).pipe(
          map(() => {
            return changePasswordSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized || error.status === HttpStatusCode.Forbidden) {
              this.navigateToShopAndOpenLoginDialog();
            } else {
              this.showErrorMessage(error.status === HttpStatusCode.Conflict ? 'profile.password.password-error' : undefined);
            }

            return of(changePasswordError({ error: mapApiErrorResponse(error) }));
          })
        );
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public changePasswordSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePasswordSuccess),
      switchMap(() => {
        this.navigateToShop().then(() => {
          this.dialog.open<LoginFormComponent>(LoginFormComponent, {
            backdropClass: 'dd-blur-backdrop',
            dialogModeWidth: '480px'
          });
          this.showSuccessMessage('profile.password-changed-logged-out');
        });

        return of(resetState());
      })
    );
  });

  // eslint-disable-next-line unicorn/consistent-function-scoping
  public getProfileError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getProfileError),
      concatLatestFrom(() => this.store.select(selectProfileVM)),
      switchMap(([{ error }, profile]) => {
        // As we use the profile to check whether someone is logged in or not, we have to check if
        // we already have a profile when we get an unauthorized error. We only log the user out when
        // a profile existed previously.
        if (!!profile.personalDetails && error.status === HttpStatusCode.Unauthorized) {
          this.navigateToShopAndOpenLoginDialog();
          return of(resetState());
        }
        return of(noopAction());
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly profileDataService: ProfileDataService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly toastMessageService: ToastMessageService,
    private readonly translateService: TranslateService,
    private readonly dialog: BottomSheetAndDialog,
    private readonly tagPusherService: TagPusherService,
    private readonly autoLogoutService: AutoLogoutService
  ) {}

  private navigateToProfile(): void {
    this.router.navigate([RouteSegment.Root, RouteSegment.Dashboard, RouteSegment.Profile]);
  }

  private navigateToShop(): Promise<boolean> {
    return this.router.navigate([RouteSegment.Root]);
  }

  private showSuccessMessage(successMessage = 'profile.changes-saved'): void {
    this.toastMessageService.open(this.translateService.stream(successMessage));
  }

  private showErrorMessage(errorMesssage = 'profile.an-error-occurred'): void {
    this.toastMessageService.open(this.translateService.stream(errorMesssage), { type: ToastMessageType.Error });
  }

  private navigateToShopAndOpenLoginDialog(): void {
    this.navigateToShop().then(() => {
      this.dialog.open<LoginFormComponent>(LoginFormComponent, {
        backdropClass: 'dd-blur-backdrop',
        dialogModeWidth: '480px'
      });
    });
  }
}
