import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { debounceTimeAfterFirst } from '@shared/helpers/debounce-after-first.helper';
import { selectProfileVM } from '@shared/store/profile/profile.selectors';
import { map, Subject } from 'rxjs';
import { login, logout, requestSecondFactorCode, resetState, secondFactorAuthenticate } from './store/auth.actions';
import { selectAuth, selectSecondFactorAuth, selectSecondFactorCodeRequest } from './store/auth.selectors';

@Injectable()
export class AuthService {
  public readonly auth$ = this.store.select(selectAuth);
  public readonly secondFactorAuth$ = this.store.select(selectSecondFactorAuth);
  public readonly profile$ = this.store.select(selectProfileVM);
  public readonly secondFactorCodeRequest$ = this.store.select(selectSecondFactorCodeRequest);
  public readonly requestSecondFactorCode$ = new Subject<boolean | void>();
  private readonly DEFAULT_CODE_DEBOUNCE_TIME = 800;

  constructor(private readonly store: Store) {
    this.subscribeToSecondFactorCodeRequests();
  }

  public login(email: string, password: string, shouldNavigate = true): void {
    this.store.dispatch(login({ email, password, shouldNavigate }));
  }

  public secondFactorAuthenticate(code: string, shouldNavigate = true): void {
    this.store.dispatch(secondFactorAuthenticate({ code, shouldNavigate }));
  }

  public logout(showDialog = false): void {
    this.store.dispatch(logout({ showDialog }));
  }

  public resetState(): void {
    this.store.dispatch(resetState());
  }

  private subscribeToSecondFactorCodeRequests(): void {
    this.requestSecondFactorCode$
      .pipe(
        debounceTimeAfterFirst(this.DEFAULT_CODE_DEBOUNCE_TIME),
        map((isResent, count) => {
          return {
            isResent,
            count
          };
        })
      )
      .subscribe(({ isResent, count }) => {
        if (count > 0 && !isResent) {
          return;
        } else {
          this.requestSecondFactorCode(!!isResent);
        }
      });
  }

  private requestSecondFactorCode(isResent = false): void {
    this.store.dispatch(requestSecondFactorCode({ isResent }));
  }
}
