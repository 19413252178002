import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { appService4Icon } from '@icons/services/service-4';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HomeService } from '@shop/pages/home/home.service';
import { SkeletonLoaderModule } from '@shared/directives/skeleton-loader/skeleton-loader.module';
import { provideSvgIcons } from '@ngneat/svg-icon';
import { HeroComponent } from './components/hero/hero.component';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { featureKeyHome, reducerHome } from './store/home.reducer';
import { HomeEffects } from './store/home.effects';

@NgModule({
  declarations: [HomeComponent, HeroComponent],
  imports: [
    SharedModule,
    HomeRoutingModule,
    SkeletonLoaderModule,
    StoreModule.forFeature(featureKeyHome, reducerHome),
    EffectsModule.forFeature([HomeEffects])
  ],
  providers: [HomeService, provideSvgIcons([appService4Icon])]
})
export class HomeModule {}
